import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { Input, Label } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { PageSegment } from "../../../../Types/PageSegment"
import { PageSegmentParts } from "../../../Molecules/PageSegmentRule"
import { useSite } from "../../../../Providers/Site"
import { Message } from "../../../Ui/Message"
import { useEffect, useMemo, useState } from "react"
import { Loading } from "../../../Ui/Loading"
import { useLocation, useNavigate } from "react-router-dom"
import { getPageSegment, updatePageSegment } from "../../../../Hooks/User/PageSegment"
import TagIcon from '@mui/icons-material/Tag'
import { Page404 } from "../Page404"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const PageSegmentEdit = () => {
    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const { site } = useSite()
    const [isLoading, setIsLoading] = useState(false)
    const [segments, setSegments] = useState([[{ path: "", rule_type_id: 1 }]])
    const useFormMethods = useForm<PageSegment>()
    const { handleSubmit, getValues, setValue, setError } = useFormMethods
    
    const onSubmit: SubmitHandler<PageSegment> = (data) => {
        setIsLoading(true)
        
        updatePageSegment(data)
            .then(() => {
                navigate('/page_segments', { state: 'サイト分類を更新しました' })
            }).catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                // if (errors.company_id) setError('company_id', { type: 'custom', message: errors.company_id.join('/') })
                // if (errors.is_active) setError('is_active', { type: 'custom', message: errors.is_active.join('/') })
                // if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                // if (errors.email) setError('email', { type: 'custom', message: errors.email.join('/') })
                // if (errors.password) setError('password', { type: 'custom', message: errors.password.join('/') })
            })
    }

    useEffect(() => {
        const getData = async (id: string) => {
            const data = await getPageSegment(id)

            setValue('id', data.id)
            setValue('name', data.name)
            setValue('rules', data.rules)

            setSegments(data.rules)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    useEffect(() => {
        setValue('site_id', Number(site?.id))
    }, [site])

    if (!site) {
        return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    }
    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<TagIcon />}>ページ分類</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>ページ分類名</Label>
                        <Input name="name" type="text" />
                        
                        <Label isRequire={true}>ルール</Label>
                        <PageSegmentParts pageSegments={segments} />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}