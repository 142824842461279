import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyPageSegmentChannelPageLog, SearchMonthlyPageSegmentChannelPageLog } from '../../Types/MonthlyPageSegmentChannelPageLog'


export const getMonthlyPageSegmentChannelPageLogList = async (searchParams: SearchMonthlyPageSegmentChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/monthly_page_segment_channel_page_logs', { params: searchParams })
    return data
}

export const useMonthlyPageSegmentChannelPageLogList = (searchParams: SearchMonthlyPageSegmentChannelPageLog | undefined): UseQueryResult<MonthlyPageSegmentChannelPageLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyPageSegmentChannelPageLogList', searchParams],
        queryFn: () => getMonthlyPageSegmentChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
