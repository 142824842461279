import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVGAChannelLandingLog, GAChannelLandingLog, SearchGAChannelLandingLog } from '../../Types/GAChannelLandingLog'


export const getGAChannelLandingLogList = async (searchParams: SearchGAChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/ga_channel_landing_logs', { params: searchParams })
    return data
}

export const useGAChannelLandingLogList = (searchParams: SearchGAChannelLandingLog | undefined): UseQueryResult<GAChannelLandingLog[]> => {
    return useQuery({
        queryKey: ['getGAChannelLandingLogList', searchParams],
        queryFn: () => getGAChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVGAChannelLandingLogList = async (searchParams: SearchGAChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/conversion_ga_channel_landing_logs', { params: searchParams })
    return data
}

export const useCVGAChannelLandingLogList = (searchParams: SearchGAChannelLandingLog | undefined): UseQueryResult<CVGAChannelLandingLog> => {
    return useQuery({
        queryKey: ['getCVGAChannelLandingLogList', searchParams],
        queryFn: () => getCVGAChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}