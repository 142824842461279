import { ReactNode, createContext, useContext, useState } from "react";
import { Site } from "../Types/Site";

interface SiteContextProps {
    site: Site | null
    setSite: (value: Site) => void
}

// Contextを宣言（初期値はundefined）
const SiteContext = createContext<SiteContextProps | undefined>(undefined)

// カスタムフック
export const useSite = (): SiteContextProps => {
    const context = useContext(SiteContext)
    
    if (!context) {
        throw new Error('useSite must be used within a SiteProvider')
    }

    return context
}

// Providerコンポーネント
export const SiteProvider = ({ children }: { children?: ReactNode}) => {
    const [site, setSite] = useState<Site | null>(null)

    return (
        <SiteContext.Provider value={{ site, setSite }}>
            {children}
        </SiteContext.Provider>
    )
}