import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVPageSegmentPagePathLog, PageSegmentPagePathLog, SearchPageSegmentPagePathLog } from '../../Types/PageSegmentPagePathLog'


export const getPageSegmentPagePathLogList = async (searchParams: SearchPageSegmentPagePathLog | undefined) => {
    const { data } = await axios.get('/api/page_segment_page_path_logs', { params: searchParams })
    return data
}

export const usePageSegmentPagePathLogList = (searchParams: SearchPageSegmentPagePathLog | undefined): UseQueryResult<PageSegmentPagePathLog[]> => {
    return useQuery({
        queryKey: ['getPageSegmentPagePathLogList', searchParams],
        queryFn: () => getPageSegmentPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVPageSegmentPagePathLogList = async (searchParams: SearchPageSegmentPagePathLog | undefined) => {
    const { data } = await axios.get('/api/conversion_page_segment_page_path_logs', { params: searchParams })
    return data
}

export const useCVPageSegmentPagePathLogList = (searchParams: SearchPageSegmentPagePathLog | undefined): UseQueryResult<CVPageSegmentPagePathLog> => {
    return useQuery({
        queryKey: ['getCVPageSegmentPagePathLogList', searchParams],
        queryFn: () => getCVPageSegmentPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}