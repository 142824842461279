import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { SearchConsoleLog, SearchConsoleLogProps } from '../../Types/SearchConsole'

// クエリのみ取得
export const getSearchConsoleLogQueryList = async (searchParams: SearchConsoleLogProps | undefined) => {
    const { data } = await axios.get('/api/search_console_logs/query', { params: searchParams })
    return data
}
export const useSearchConsoleLogQueryList = (searchParams: SearchConsoleLogProps | undefined): UseQueryResult<SearchConsoleLog[]> => {
    return useQuery({
        queryKey: ['getSearchConsoleLogQueryList', searchParams],
        queryFn: () => getSearchConsoleLogQueryList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

// ページのみ取得
export const getSearchConsoleLogPageList = async (searchParams: SearchConsoleLogProps | undefined) => {
    const { data } = await axios.get('/api/search_console_logs/page', { params: searchParams })
    return data
}
export const useSearchConsoleLogPageList = (searchParams: SearchConsoleLogProps | undefined): UseQueryResult<SearchConsoleLog[]> => {
    return useQuery({
        queryKey: ['getSearchConsoleLogPageList', searchParams],
        queryFn: () => getSearchConsoleLogPageList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

// ページ+クエリ取得
export const getSearchConsoleLogPageAndQueryList = async (searchParams: SearchConsoleLogProps | undefined) => {
    const { data } = await axios.get('/api/search_console_logs/page_query', { params: searchParams })
    return data
}
export const useSearchConsoleLogPageAndQueryList = (searchParams: SearchConsoleLogProps | undefined): UseQueryResult<SearchConsoleLog[]> => {
    return useQuery({
        queryKey: ['getSearchConsoleLogPageAndQueryList', searchParams],
        queryFn: () => getSearchConsoleLogPageAndQueryList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

// 日付のみ取得
export const getSearchConsoleLogDateList = async (searchParams: SearchConsoleLogProps | undefined) => {
    const { data } = await axios.get('/api/search_console_logs/date', { params: searchParams })
    return data
}
export const useSearchConsoleLogDateList = (searchParams: SearchConsoleLogProps | undefined): UseQueryResult<SearchConsoleLog[]> => {
    return useQuery({
        queryKey: ['getSearchConsoleLogDateList', searchParams],
        queryFn: () => getSearchConsoleLogDateList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}