import { CHANNEL_GROUPS } from "../../config"
import { EChartHeatmap, EChartHorizontalBar } from "../Ui/EChart"

interface AllHorizontalBarChartProps {
    data: any
    valueTitle: string
    valueColumn: string
    labelColumn: string
    limit?: number
}
export const AllLogHorizontalBarChart = ({ data, valueTitle, valueColumn, labelColumn, limit = 0 }: AllHorizontalBarChartProps) => {
    const tmpData = limit > 0
        ? data.sort((a: any, b: any) => b[valueColumn] - a[valueColumn]).slice(0, limit)
        : data.sort((a: any, b: any) => b[valueColumn] - a[valueColumn])

    const formattedData = tmpData.sort((a: any, b: any) => a[valueColumn] - b[valueColumn])

    const labels = formattedData.map((item: any) => item[labelColumn])
    const values = formattedData.map((item: any) => item[valueColumn])

    return <EChartHorizontalBar labels={labels} values={values} valueTitle={valueTitle} />
}


// チャネルｘ上位LPなどののヒートマップ
interface ChannelLandingApiData {
    session_default_channel_group: string
    landing_page_plus_query_string: string
    sessions: number
}
export const ChannelHeatmapChart = ({ apiData, valueColumn, xAxisColumn }: { apiData: any, valueColumn: string, xAxisColumn: string }) => {
    const { xAxisData, yAxisData, heatmapData } = processChannelHeatmapData(apiData, valueColumn, xAxisColumn);
    return <EChartHeatmap xAxisData={xAxisData} yAxisData={yAxisData} data={heatmapData} column={valueColumn} />
}
const processChannelHeatmapData = (apiData: ChannelLandingApiData[], valueColumn: string, xAxisColumn: string, max=15) => {
    // データをユニーク化し valueColumn(sessions or conversions)を合算
    const uniqueLpMap = new Map<string, { [key: string]: number }>() // LPでユニーク
    const heatmapMap = new Map<string, number>() // チャネル||LP をキーにvalueColumnをまとめる
  
    apiData.forEach((item: any) => {
        // LP毎にvalueColumnを合算
        const xDataKey = item[xAxisColumn]
        if (uniqueLpMap.has(xDataKey)) {
            uniqueLpMap.get(xDataKey)![valueColumn] += item[valueColumn]
        } else {
            uniqueLpMap.set(xDataKey, { [valueColumn]: item[valueColumn] })
        }

        // チャネル-LP毎にvalueColumnを合算
        const heatmapKey = `${item.session_default_channel_group}||${item[xAxisColumn]}`
        if (heatmapMap.has(heatmapKey)) {
            heatmapMap.set(heatmapKey, heatmapMap.get(heatmapKey)! + item[valueColumn])
        } else {
            heatmapMap.set(heatmapKey, item[valueColumn])
        }
    })

    // LP毎のデータからvalueColumnが多い順にmax件取得（['/xxxx/', '/yyyy/', ...]）
    const xAxisData = Array.from(uniqueLpMap.entries())
        .sort((a, b) => b[1][valueColumn] - a[1][valueColumn])
        .slice(0, max)
        .map(item => item[0])

    // CHANNEL_GROUPS を逆順に設定
    const yAxisData = [...CHANNEL_GROUPS].reverse()
  
    // データをヒートマップ形式に加工（[[0, 0, 1], [0, 1, 0]...[x座標, y座標, セッション数]]）
    const heatmapData: (number | null)[][] = []
    yAxisData.map((yData) => {
        xAxisData.map((xData) => {
            heatmapData.push([
                xAxisData.indexOf(xData),
                yAxisData.indexOf(yData), 
                heatmapMap.get(`${yData}||${xData}`) || null,
            ])
        })
    })
    
    return {
        xAxisData: xAxisData,
        yAxisData: yAxisData,
        heatmapData,
    }
}

// // チャネルｘ上位ページパス のヒートマップ
// interface ChannelPageApiData {
//     session_default_channel_group: string
//     page_path_plus_query_string: string
//     sessions: number
// }
// export const ChannelPageHeatmapChart = ({ apiData, column }: { apiData: any, column: string }) => {
//     const { xAxisData, yAxisData, heatmapData } = processChannelPageHeatmapData(apiData, column);
//     return <EChartHeatmap xAxisData={xAxisData} yAxisData={yAxisData} data={heatmapData} column={column} />
// }
// const processChannelPageHeatmapData = (apiData: ChannelPageApiData[], column: string, max=15) => {
//     // データをユニーク化し column(sessions or conversions)を合算
//     const uniquePageMap = new Map<string, { [key: string]: number }>() // ページパスでユニーク
//     const heatmapMap = new Map<string, number>() // チャネル||ページパス をキーにcolumnをまとめる
  
//     apiData.forEach((item: any) => {
//         // LP毎にcolumnを合算
//         const xDataKey = item.page_path_plus_query_string
//         if (uniquePageMap.has(xDataKey)) {
//             uniquePageMap.get(xDataKey)![column] += item[column]
//         } else {
//             uniquePageMap.set(xDataKey, { [column]: item[column] })
//         }

//         // チャネル-LP毎にcolumnを合算
//         const heatmapKey = `${item.session_default_channel_group}||${item.page_path_plus_query_string}`
//         if (heatmapMap.has(heatmapKey)) {
//             heatmapMap.set(heatmapKey, heatmapMap.get(heatmapKey)! + item[column])
//         } else {
//             heatmapMap.set(heatmapKey, item[column])
//         }
//     })

//     // LP毎のデータからcolumnが多い順にmax件取得（['/xxxx/', '/yyyy/', ...]）
//     const xAxisData = Array.from(uniquePageMap.entries())
//         .sort((a, b) => b[1][column] - a[1][column])
//         .slice(0, max)
//         .map(item => item[0])

//     // CHANNEL_GROUPS を逆順に設定
//     const yAxisData = [...CHANNEL_GROUPS].reverse()
  
//     // データをヒートマップ形式に加工（[[0, 0, 1], [0, 1, 0]...[x座標, y座標, セッション数]]）
//     const heatmapData: (number | null)[][] = []
//     yAxisData.map((yData) => {
//         xAxisData.map((xData) => {
//             heatmapData.push([
//                 xAxisData.indexOf(xData),
//                 yAxisData.indexOf(yData), 
//                 heatmapMap.get(`${yData}||${xData}`) || null,
//             ])
//         })
//     })
    
//     return {
//         xAxisData: xAxisData,
//         yAxisData: yAxisData,
//         heatmapData,
//     }
// }

// // チャネルｘ上位ページ分類 のヒートマップ
// interface ChannelLandingApiData {
//     session_default_channel_group: string
//     landing_page_plus_query_string: string
//     sessions: number
// }
// export const ChannelPageSegmentHeatmapChart = ({ apiData, column }: { apiData: any, column: string }) => {
//     const { xAxisData, yAxisData, heatmapData } = processChannelPageSegmentHeatmapData(apiData, column);
//     return <EChartHeatmap xAxisData={xAxisData} yAxisData={yAxisData} data={heatmapData} column={column} />
// }
// const processChannelPageSegmentHeatmapData = (apiData: ChannelLandingApiData[], column: string, max=15) => {
//     // データをユニーク化し column(sessions or conversions)を合算
//     const uniqueLpMap = new Map<string, { [key: string]: number }>() // LPでユニーク
//     const heatmapMap = new Map<string, number>() // チャネル||LP をキーにcolumnをまとめる
  
//     apiData.forEach((item: any) => {
//         // LP毎にcolumnを合算
//         const xDataKey = item.page_segment_name
//         if (uniqueLpMap.has(xDataKey)) {
//             uniqueLpMap.get(xDataKey)![column] += item[column]
//         } else {
//             uniqueLpMap.set(xDataKey, { [column]: item[column] })
//         }

//         // チャネル-LP毎にcolumnを合算
//         const heatmapKey = `${item.session_default_channel_group}||${item.page_segment_name}`
//         if (heatmapMap.has(heatmapKey)) {
//             heatmapMap.set(heatmapKey, heatmapMap.get(heatmapKey)! + item[column])
//         } else {
//             heatmapMap.set(heatmapKey, item[column])
//         }
//     })

//     // LP毎のデータからcolumnが多い順にmax件取得（['/xxxx/', '/yyyy/', ...]）
//     const xAxisData = Array.from(uniqueLpMap.entries())
//         .sort((a, b) => b[1][column] - a[1][column])
//         .slice(0, max)
//         .map(item => item[0])

//     // CHANNEL_GROUPS を逆順に設定
//     const yAxisData = [...CHANNEL_GROUPS].reverse()
  
//     // データをヒートマップ形式に加工（[[0, 0, 1], [0, 1, 0]...[x座標, y座標, セッション数]]）
//     const heatmapData: (number | null)[][] = []
//     yAxisData.map((yData) => {
//         xAxisData.map((xData) => {
//             heatmapData.push([
//                 xAxisData.indexOf(xData),
//                 yAxisData.indexOf(yData), 
//                 heatmapMap.get(`${yData}||${xData}`) || null,
//             ])
//         })
//     })
    
//     return {
//         xAxisData: xAxisData,
//         yAxisData: yAxisData,
//         heatmapData,
//     }
// }