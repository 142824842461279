import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVGAChannelPageLog, GAChannelPageLog, SearchGAChannelPageLog } from '../../Types/GAChannelPageLog'


export const getGAChannelPageLogList = async (searchParams: SearchGAChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/ga_channel_page_logs', { params: searchParams })
    return data
}

export const useGAChannelPageLogList = (searchParams: SearchGAChannelPageLog | undefined): UseQueryResult<GAChannelPageLog[]> => {
    return useQuery({
        queryKey: ['getGAChannelPageLogList', searchParams],
        queryFn: () => getGAChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVGAChannelPageLogList = async (searchParams: SearchGAChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/conversion_ga_channel_page_logs', { params: searchParams })
    return data
}

export const useCVGAChannelPageLogList = (searchParams: SearchGAChannelPageLog | undefined): UseQueryResult<CVGAChannelPageLog> => {
    return useQuery({
        queryKey: ['getCVGAChannelPageLogList', searchParams],
        queryFn: () => getCVGAChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}