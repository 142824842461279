import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllChannelPageLog, SearchAllChannelPageLog } from '../../Types/AllChannelPageLog'


export const getAllChannelPageLogList = async (searchParams: SearchAllChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/grouped_ga_channel_page_logs', { params: searchParams })
    return data
}

export const useAllChannelPageLogList = (searchParams: SearchAllChannelPageLog | undefined): UseQueryResult<AllChannelPageLog[]> => {
    return useQuery({
        queryKey: ['getAllChannelPageLogList', searchParams],
        queryFn: () => getAllChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
