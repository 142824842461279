import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label } from "../../../../Ui/Form"
import { SubmitButton } from "../../../../Ui/Button"
import { useEffect, useMemo, useState } from "react"
import { Loading } from "../../../../Ui/Loading"
import { useLocation, useNavigate } from "react-router-dom"
import { Conversion, ConversionRule } from "../../../../../Types/Conversion"
import { getSite } from "../../../../../Hooks/User/Site"
import { Site } from "../../../../../Types/Site"
import { Page404 } from "../../Page404"
import { ConversionRuleParts } from "../../../../Molecules/ConversionRule"
import { getConversion, updateConversion } from "../../../../../Hooks/User/Conversion"
import LanguageIcon from '@mui/icons-material/Language'

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionEdit = () => {
    const query = useQueryString()
    const siteId = query.get('site_id')
    const id = query.get('id')

    if (!siteId || !id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [rules, setRules] = useState<ConversionRule[]>([{ type: 'event', data: '' }])
    const [site, setSite] = useState<Site>()
    const useFormMethods = useForm<Conversion>()
    const { handleSubmit, setValue, setError } = useFormMethods
    
    const onSubmit: SubmitHandler<Conversion> = (data) => {
        setIsLoading(true)
        
        updateConversion(data)
            .then(() => {
                navigate(`/sites/conversions?site_id=${siteId}`, { state: 'コンバージョン設定を更新しました' })
            }).catch((error) => {
                const errors = error.response.data.errors

                Object.entries(errors).forEach(([field, messages]) => {
                    const messageArray = messages as string[]
                    setError(field as keyof Conversion, { type: 'custom', message: messageArray.join('/') })
                })

                setIsLoading(false)
            })
    }

    useEffect(() => {
        const getData = async (siteId: string) => {
            const site = await getSite(siteId)
            const conversion = await getConversion(id)

            setSite(site)
            setRules(conversion.rules)

            setValue('name', conversion.name)
            setValue('rules', conversion.rules)

            setIsLoading(false)
        }

        getData(siteId)
    }, [])

    useEffect(() => {
        setValue('id', Number(id))
        setValue('site_id', Number(site?.id))
        setValue('rules', rules)
    }, [siteId, rules, setValue])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 className="mb-12" icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}コンバージョン設定）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>コンバージョン名</Label>
                        <Input name="name" type="text" />
                        
                        <Label isRequire={true}>ルール</Label>
                        <ConversionRuleParts conversionRules={rules} setConversionRules={setRules} siteId={siteId} />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}