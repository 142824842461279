import { FC } from "react"
import { Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../Ui/Loading"
import { Message } from "../../../Ui/Message"
import { H1 } from "../../../Ui/H1"
import { PaginationTable } from "../../../Ui/Table"
import { CreateButton } from "../../../Ui/Button"
import { useSiteList } from "../../../../Hooks/User/Site"
import { UserSiteList } from "../../../../Types/Site"


export const SiteList: FC = () => {
    const { state: message } = useLocation()

    // データ取得
    const { isPending, data } = useSiteList()

    const columnHelper = createColumnHelper<UserSiteList>()
    const columns = setColumns(columnHelper)

    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>        
            <Message message={message} />
            <H1 icon={<LanguageIcon />}>サイト設定</H1>
            
            <PaginationTable data={data} columns={columns}>
                <CreateButton path="/sites/create" className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<UserSiteList>) => {
    return [
        columnHelper.accessor('name', {
            header: 'サイト名',
            cell: (props) => (
                <>
                    <p>{props.row.original.name}</p>
                    <p>{props.row.original.url}</p>
                </>
            )
        }),
        columnHelper.accessor('ga_property.name', {
            header: 'GA4連携',
            cell: (props) => (
                <Link to={`/sites/ga_setting?id=${props.row.original.id}`}>
                    <Button>
                        {props.row.original.ga_property?.property_id ? props.row.original.ga_property.name : '未設定'}
                    </Button>
                </Link>
            )
        }),
        columnHelper.accessor('search_console_setting.url', {
            header: () => (
                <span className="normal-case">SearchConsole連携</span>
            ),
            cell: (props) => (
                <Link to={`/sites/search_console_settings?id=${props.row.original.id}`}>
                    <Button>
                        {props.row.original.search_console_setting?.url ? '連携済み' : '未設定'}
                    </Button>
                </Link>
            )
        }),
        columnHelper.accessor('user_count', {
            header: '目標設定',
            cell: (props) => (
                <>
                    {props.row.original.ga_property?.property_id ? (
                        <Link to={`/sites/monthly_targets?id=${props.row.original.id}`}>
                            <Button>
                                {props.row.original.monthly_target_count > 0 ? '設定済み' : '未設定'}
                            </Button>
                        </Link>
                    ) : (
                        <Button onClick={() => alert('先にGA4連携をしてください')}>---</Button>
                    )}
                </>
            )
        }),
        columnHelper.accessor('user_count', {
            header: 'CV設定',
            cell: (props) => (
                <>
                    {props.row.original.ga_property?.property_id ? (
                        <Link to={`/sites/conversions?site_id=${props.row.original.id}`}>
                            <Button>
                                {props.row.original.conversion_count > 0 ? `設定済み(${props.row.original.conversion_count})` : '未設定'}
                            </Button>
                        </Link>
                    ) : (
                        <Button onClick={() => alert('先にGA4連携をしてください')}>---</Button>
                    )}
                </>
            )
        }),
        columnHelper.accessor('user_count', {
            header: 'フォーム設定',
            cell: (props) => (
                <>
                    {props.row.original.ga_property?.property_id ? (
                        <Link to={`/sites/conversion_page_path_settings?id=${props.row.original.id}`}>
                            <Button>
                                {props.row.original.conversion_page_path_setting_count > 0 ? `設定済み(${props.row.original.conversion_page_path_setting_count})` : '未設定'}
                            </Button>
                        </Link>
                    ) : (
                        <Button onClick={() => alert('先にGA4連携をしてください')}>---</Button>
                    )}
                </>
            )
        }),
        columnHelper.accessor('user_count', {
            header: '共有ユーザー',
            cell: (props) => (
                <Link to={`/sites/site_users?id=${props.row.original.id}`}>
                    <Button>
                        {props.row.original.user_count}人
                    </Button>
                </Link>
            )
        }),
        // columnHelper.accessor('updated_at', {
        //     header: '最終更新日',
        //     cell: (props) => (
        //         format(props.row.original.updated_at, 'yyyy/MM/dd HH:mm')
        //     )
        // }),
        columnHelper.display({
            id: 'action',
            header: '',
            cell: (props) => (
                <>
                    <Link to={`/sites/edit?id=${props.row.original.id}`}><Button>編集</Button></Link>
                </>
            )
        })
    ]
}