import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVGALandingPageLog, GALandingPageLog, SearchGALandingPageLog } from '../../Types/GALandingPageLog'


export const getGALandingPageLogList = async (searchParams: SearchGALandingPageLog | undefined) => {
    const { data } = await axios.get('/api/ga_landing_page_logs', { params: searchParams })
    return data
}

export const useGALandingPageLogList = (searchParams: SearchGALandingPageLog | undefined): UseQueryResult<GALandingPageLog[]> => {
    return useQuery({
        queryKey: ['getGALandingPageLogList', searchParams],
        queryFn: () => getGALandingPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVGALandingPageLogList = async (searchParams: SearchGALandingPageLog | undefined) => {
    const { data } = await axios.get('/api/conversion_ga_landing_page_logs', { params: searchParams })
    return data
}

export const useCVGALandingPageLogList = (searchParams: SearchGALandingPageLog | undefined): UseQueryResult<CVGALandingPageLog> => {
    return useQuery({
        queryKey: ['getCVGALandingPageLogList', searchParams],
        queryFn: () => getCVGALandingPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}