import { FC, useContext, useState } from "react"
import { Avatar, Button } from "@mui/material"
import styled from "@emotion/styled"
import LogoutIcon from '@mui/icons-material/Logout'
import { useTheme } from '@mui/material/styles'
import { AuthContext } from "../../../Providers/UserAuth"
import { CircularProgressButton } from "../../Ui/Button"
import { logout } from "../../../Hooks/User/Auth"
import { useLocation } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { SearchSite } from "../../../Types/Site"
import { useSite } from "../../../Providers/Site"
import { SiteSelect } from "../../Molecules/SiteSelect"
import { getSite } from "../../../Hooks/User/Site"

const TextButton = styled(Button)`
    text-transform: none;
`

export const Header: FC = () => {

    const authUser = useContext(AuthContext)
    const { setSite } = useSite()
    const [isLoading, setIsLoading] = useState(false)

    const onLogout = () => {
        setIsLoading(true)
        logout()
            .then(() => {
                window.location.href = '/login'        
            })
    }

    // /analytics/*, /page_segments/*, /search_console/* のパスの場合のみ、特定のプルダウンを表示させる
    const pattern = /^\/analytics\/*|^\/page_segments\/*|^\/search_console\/*/
    const location = useLocation()
    const isGAPage = pattern.test(location.pathname)

    const useFormMethods = useForm<SearchSite>()

    const changeSite = async (siteId: string | number) => {
        const site = await getSite(String(siteId))
        setSite(site)
    }

    const theme = useTheme()

    return (
        <header className="sticky top-0 z-40 flex-none w-full mx-auto bg-white border-b border-gray-200 dark:border-gray-600 dark:bg-gray-800">

            <div className="flex items-center justify-between w-full px-3 py-3 mx-auto max-w-8xl lg:px-4">
                <div className="flex items-center">
                    
                    <div className="flex items-center justify-between">
                        <a href="/" className="flex">
                            <img src={theme.palette.mode === 'dark' ? '/image/logo_dark.png' : '/image/logo_light.png'} className="h-8" />
                        </a>
                    </div>

                    {isGAPage && (
                        <div className="flex ml-6">
                            <FormProvider {...useFormMethods}>
                                <SiteSelect name="site_id" onChange={changeSite} />
                            </FormProvider>
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-between">
                    <ul id="flowbiteMenu" className="flex-col hidden lg:flex-row lg:self-center lg:py-0 lg:flex">
                        <Avatar 
                            alt={authUser && authUser.name}
                            src={authUser && authUser.logo}
                            sx={{ width: 36, height: 36, marginRight: .5, position: "relative", top: 0 }} 
                        />
                        <li className="hidden pr-6 pl-1 lg:mb-0 xl:block text-sm font-medium text-gray-900 dark:text-gray-300">
                            {authUser && authUser.company?.name}<br />
                            {authUser && authUser.name}
                        </li>
                        <li className="relative">
                            <CircularProgressButton isLoading={isLoading} onClick={onLogout} endIcon={<LogoutIcon />}>Logout</CircularProgressButton>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}