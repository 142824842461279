import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyPageSegmentChannelLandingLog, SearchMonthlyPageSegmentChannelLandingLog } from '../../Types/MonthlyPageSegmentChannelLandingLog'


export const getMonthlyPageSegmentChannelLandingLogList = async (searchParams: SearchMonthlyPageSegmentChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/monthly_page_segment_channel_landing_logs', { params: searchParams })
    return data
}

export const useMonthlyPageSegmentChannelLandingLogList = (searchParams: SearchMonthlyPageSegmentChannelLandingLog | undefined): UseQueryResult<MonthlyPageSegmentChannelLandingLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyPageSegmentChannelLandingLogList', searchParams],
        queryFn: () => getMonthlyPageSegmentChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
