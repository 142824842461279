import axios, { AxiosResponse } from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { SearchConsoleLog, SearchConsoleLogProps, SearchConsoleSite } from '../../Types/SearchConsole'

/**
 * サイト関連
 */
export const getSearchConsoleSiteList = async (site_id: string) => {
    const { data } = await axios.get(`/api/search_console/sites/`, {
        params: {
            site_id: site_id
        }
    })
    return data
}

// use react-query
export const useSearchConsoleSiteList = (site_id: string): UseQueryResult<SearchConsoleSite[]> => {
    return useQuery({
        queryKey: ['getSearchConsoleSiteList', site_id],
        queryFn: () => getSearchConsoleSiteList(site_id),
    })
}

export const useSearchConsoleSiteUrlSelectOptions = (site_id: string) => {
    const { data: propertyList, ...queryInfo } = useSearchConsoleSiteList(site_id)

    const options = useMemo(() => {
        if (!propertyList) return []

        return propertyList.map((searchConsoleSite: SearchConsoleSite) => (
            {
                label: `${searchConsoleSite.siteUrl}`,
                value: searchConsoleSite.siteUrl,
            }
        ))
    }, [propertyList])

    return { options, ...queryInfo }
}

export const getSearchConsoleThisMonthTop5QueryList = async (searchParams: SearchConsoleLogProps | undefined) => {
    const { data } = await axios.get('/api/search_console/this_month_top5_query', { params: searchParams })
    return data
}

// use react-query
export const useSearchConsoleThisMonthTop5QueryList = (searchParams: SearchConsoleLogProps | undefined): UseQueryResult<SearchConsoleLog[]> => {
    return useQuery({
        queryKey: ['getSearchConsoleThisMonthTop5QueryList', searchParams],
        queryFn: () => getSearchConsoleThisMonthTop5QueryList(searchParams),
        enabled: !!searchParams, // site_idがundefinedの場合はクエリを無効にする
    })
}
