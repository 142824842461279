import { useSearchConsoleSiteUrlSelectOptions } from "../../Hooks/User/SearchConsole"
import { Select } from "../Ui/Form"

export const SearchConsoleSiteUrlSelect = ({ site_id, name, labelName, placeholder='対象のURLを選択してください', onChange, className }: { site_id: string, name: string, labelName?: string, placeholder?: string, onChange?: (newValue: number | string) => void, className?: string }) => {
    const { options, isLoading, isError } = useSearchConsoleSiteUrlSelectOptions(site_id)

    if (isLoading) return <span className="text-xs">利用可能なサイトURLを取得中です...</span>
    if (isError) {
        window.location.href = `/auth/google_oauth/callback?site_id=${site_id}`
    }

    return (
        <div className={className ? className : `inline-block w-auto`}>
            <Select name={name} labelName={labelName} options={options} placeholder={placeholder} onChange={onChange} />
        </div>
    )
}