import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllPageSegmentChannelPageLog, SearchAllPageSegmentChannelPageLog } from '../../Types/AllPageSegmentChannelPageLog'


export const getAllPageSegmentChannelPageLogList = async (searchParams: SearchAllPageSegmentChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/grouped_page_segment_channel_page_logs', { params: searchParams })
    return data
}

export const useAllPageSegmentChannelPageLogList = (searchParams: SearchAllPageSegmentChannelPageLog | undefined): UseQueryResult<AllPageSegmentChannelPageLog[]> => {
    return useQuery({
        queryKey: ['getAllPageSegmentChannelPageLogList', searchParams],
        queryFn: () => getAllPageSegmentChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
