import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVPageSegmentChannelLandingLog, PageSegmentChannelLandingLog, SearchPageSegmentChannelLandingLog } from '../../Types/PageSegmentChannelLandingLog'


export const getPageSegmentChannelLandingLogList = async (searchParams: SearchPageSegmentChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/page_segment_channel_landing_logs', { params: searchParams })
    return data
}

export const usePageSegmentChannelLandingLogList = (searchParams: SearchPageSegmentChannelLandingLog | undefined): UseQueryResult<PageSegmentChannelLandingLog[]> => {
    return useQuery({
        queryKey: ['getPageSegmentChannelLandingLogList', searchParams],
        queryFn: () => getPageSegmentChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVPageSegmentChannelLandingLogList = async (searchParams: SearchPageSegmentChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/conversion_page_segment_channel_landing_logs', { params: searchParams })
    return data
}

export const useCVPageSegmentChannelLandingLogList = (searchParams: SearchPageSegmentChannelLandingLog | undefined): UseQueryResult<CVPageSegmentChannelLandingLog> => {
    return useQuery({
        queryKey: ['getCVPageSegmentChannelLandingLogList', searchParams],
        queryFn: () => getCVPageSegmentChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}