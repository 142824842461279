import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllChannelLandingLog, SearchAllChannelLandingLog } from '../../Types/AllChannelLandingLog'


export const getAllChannelLandingLogList = async (searchParams: SearchAllChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/grouped_ga_channel_landing_logs', { params: searchParams })
    return data
}

export const useAllChannelLandingLogList = (searchParams: SearchAllChannelLandingLog | undefined): UseQueryResult<AllChannelLandingLog[]> => {
    return useQuery({
        queryKey: ['getAllChannelLandingLogList', searchParams],
        queryFn: () => getAllChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
