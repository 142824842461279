import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label } from "../../../../Ui/Form"
import { SubmitButton } from "../../../../Ui/Button"
import { useEffect, useMemo, useState } from "react"
import { Loading } from "../../../../Ui/Loading"
import { useLocation, useNavigate } from "react-router-dom"
import { Conversion } from "../../../../../Types/Conversion"
import { getSite } from "../../../../../Hooks/User/Site"
import { Site } from "../../../../../Types/Site"
import { Page404 } from "../../Page404"
import { ConversionRuleParts } from "../../../../Molecules/ConversionRule"
import { createConversion } from "../../../../../Hooks/User/Conversion"
import LanguageIcon from '@mui/icons-material/Language'

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionCreate = () => {
    const query = useQueryString()
    const siteId = query.get('site_id')

    if (!siteId) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [rules, setRules] = useState([{ type: "event", data: "" }]) // 初回に１行表示させる
    const [site, setSite] = useState<Site>()
    const useFormMethods = useForm<Conversion>()
    const { handleSubmit, setValue, setError } = useFormMethods

    const onSubmit: SubmitHandler<Conversion> = (data) => {
        setIsLoading(true)

        createConversion(data)
            .then(() => {
                navigate(`/sites/conversions?site_id=${siteId}`, { state: 'コンバージョン設定を登録しました' })
            }).catch((error) => {
                const errors = error.response.data.errors

                Object.entries(errors).forEach(([field, messages]) => {
                    const messageArray = messages as string[]
                    setError(field as keyof Conversion, { type: 'custom', message: messageArray.join('/') })
                })

                setIsLoading(false)
            })
    }

    useEffect(() => {
        const getData = async (site_id: string) => {
            const site = await getSite(site_id)
            setSite(site)
        }

        getData(siteId)
    }, [])

    useEffect(() => {
        setValue('site_id', Number(site?.id))
        setValue('rules', rules)
    }, [siteId, rules, setValue])
    
    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 className="mb-12" icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}コンバージョン設定）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>コンバージョン名</Label>
                        <Input name="name" type="text" />
                        
                        <Label isRequire={true}>ルール</Label>
                        <ConversionRuleParts conversionRules={rules} setConversionRules={setRules} siteId={siteId} />

                        <div className="mt-8">
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}