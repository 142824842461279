import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyChannelLandingLog, SearchMonthlyChannelLandingLog } from '../../Types/MonthlyChannelLandingLog'


export const getMonthlyChannelLandingLogList = async (searchParams: SearchMonthlyChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/monthly_channel_landing_logs', { params: searchParams })
    return data
}

export const useMonthlyChannelLandingLogList = (searchParams: SearchMonthlyChannelLandingLog | undefined): UseQueryResult<MonthlyChannelLandingLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyChannelLandingLogList', searchParams],
        queryFn: () => getMonthlyChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
