import { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label, Radio } from "../../../../Ui/Form"
import { SubmitButton } from "../../../../Ui/Button"
import { Site } from "../../../../../Types/Site"
import { getSite } from "../../../../../Hooks/User/Site"
import { CreateMonthlyTarget } from "../../../../../Types/MonthlyTarget"
import { createMonthlyTarget } from "../../../../../Hooks/User/MonthlyTarget"
import { MonthlyTargetYearSelect } from "../../../../Molecules/MonthlyTargetYearSelect"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const MonthlyTargetCreate: FC = () => {

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [site, setSite] = useState<Site>()
    const [radioValue, setRadioValue] = useState<number>(1)

    const useFormMethods = useForm<CreateMonthlyTarget>()
    const { handleSubmit, setValue, setError } = useFormMethods

    // ラジオボタンの選択肢
    const radioItems = [
        { value: 1, label: '全ての月で同じ目標値にする' },
        { value: 0, label: '毎月異なる目標値を設定する' },
    ]

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioValue(parseInt(event.target.value))
    }

    const onSubmit: SubmitHandler<CreateMonthlyTarget> = (data) => {
        setIsLoading(true)

        createMonthlyTarget(data)
            .then(() => {
                navigate(`/sites/monthly_targets?id=${id}`, { state: '目標を設定しました' })
            })
            .catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.year) setError('year', { type: 'custom', message: errors.year.join('/') })
                if (errors.is_same_target) setError('is_same_target', { type: 'custom', message: errors.is_same_target.join('/') })
                if (errors.sessions) setError('sessions', { type: 'custom', message: errors.sessions.join('/') })
                if (errors.conversions) setError('conversions', { type: 'custom', message: errors.conversions.join('/') })
            })
    }

    useEffect(() => {
        const getData = async (id: string) => {
            const site = await getSite(id)
            setSite(site)
            setValue('site_id', site.id)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}目標設定）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>目標設定したい年</Label>
                        <MonthlyTargetYearSelect name="year" />
                    
                        <Label isRequire={true}>設定方法</Label>
                        <Radio name="is_same_target" items={radioItems} onChange={handleRadioChange}/>

                        {/* ラジオボタンの選択肢に応じて、フォーム項目を表示 */}
                        {radioValue === 1 && (
                            <>
                                <Label isRequire={true}>目標セッション数</Label>
                                <Input name="sessions" type="text" />

                                <Label isRequire={true}>目標コンバージョン数</Label>
                                <Input name="conversions" type="text" />
                            </>
                        )}

                        {radioValue !== 1 && (
                            <>
                                <Label isRequire={true}>目標セッション数</Label>
                                <div className="grid grid-cols-6 gap-4">
                                    <div>1月<Input name="sessions[1]" type="text" /></div>
                                    <div>2月<Input name="sessions[2]" type="text" /></div>
                                    <div>3月<Input name="sessions[3]" type="text" /></div>
                                    <div>4月<Input name="sessions[4]" type="text" /></div>
                                    <div>5月<Input name="sessions[5]" type="text" /></div>
                                    <div>6月<Input name="sessions[6]" type="text" /></div>
                                </div>
                                <div className="grid grid-cols-6 gap-4">
                                    <div>7月<Input name="sessions[7]" type="text" /></div>
                                    <div>8月<Input name="sessions[8]" type="text" /></div>
                                    <div>9月<Input name="sessions[9]" type="text" /></div>
                                    <div>10月<Input name="sessions[10]" type="text" /></div>
                                    <div>11月<Input name="sessions[11]" type="text" /></div>
                                    <div>12月<Input name="sessions[12]" type="text" /></div>
                                </div>
                                
                                <Label isRequire={true}>目標コンバージョン数</Label>
                                <div className="grid grid-cols-6 gap-4">
                                    <div>1月<Input name="conversions[1]" type="text" /></div>
                                    <div>2月<Input name="conversions[2]" type="text" /></div>
                                    <div>3月<Input name="conversions[3]" type="text" /></div>
                                    <div>4月<Input name="conversions[4]" type="text" /></div>
                                    <div>5月<Input name="conversions[5]" type="text" /></div>
                                    <div>6月<Input name="conversions[6]" type="text" /></div>
                                </div>
                                <div className="grid grid-cols-6 gap-4">
                                    <div>7月<Input name="conversions[7]" type="text" /></div>
                                    <div>8月<Input name="conversions[8]" type="text" /></div>
                                    <div>9月<Input name="conversions[9]" type="text" /></div>
                                    <div>10月<Input name="conversions[10]" type="text" /></div>
                                    <div>11月<Input name="conversions[11]" type="text" /></div>
                                    <div>12月<Input name="conversions[12]" type="text" /></div>
                                </div>
                            </>
                        )}
                        <Input name="site_id" type="hidden" />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}