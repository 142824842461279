import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { MonthlyChannelPageLog, SearchMonthlyChannelPageLog } from '../../Types/MonthlyChannelPageLog'


export const getMonthlyChannelPageLogList = async (searchParams: SearchMonthlyChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/monthly_channel_page_logs', { params: searchParams })
    return data
}

export const useMonthlyChannelPageLogList = (searchParams: SearchMonthlyChannelPageLog | undefined): UseQueryResult<MonthlyChannelPageLog[]> => {
    return useQuery({
        queryKey: ['getMonthlyChannelPageLogList', searchParams],
        queryFn: () => getMonthlyChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
