import { FC, useEffect, useState } from "react"
import { AccessorKeyColumnDef, ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { useSite } from "../../../../Providers/Site"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Message } from "../../../Ui/Message"
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { PaginationTable } from "../../../Ui/Table"
import { roundWithScale } from "../../../../Utils/Math"
import { secToTime } from "../../../../Utils/Time"
import { PageSegmentPagePathLog, SearchPageSegmentPagePathLog } from "../../../../Types/PageSegmentPagePathLog"
import { useCVPageSegmentPagePathLogList } from "../../../../Hooks/User/PageSegmentPagePathLog"
import { CSVDownloadButton } from "../../../Ui/Button"
import { SearchModal } from "../../../Molecules/SearchModal"
import { DatePicker, Label } from "../../../Ui/Form"
import { Card, CardContent } from "@mui/material"
import { DailyPageSegmentPagePathConversionLineBarChart, DailyPageSegmentPagePathSessionLineBarChart } from "../../../Molecules/DailyLogEChart"
import { ConversionMultiSelect } from "../../../Molecules/ConversionSelect"
import { PageSegmentMultiSelect } from "../../../Molecules/PageSegmentSelect"
import { createConversionColumns } from "../../../../Utils/ColumnHelper"

export const CVDailyPageSegmentPagePathLog: FC = () => {
    const [message, setMessage] = useState('')
    const [data, setData] = useState<PageSegmentPagePathLog[]>([])
    const [conversions, setConversions] = useState<{[key: string]: string}>({})

    const [searchParams, setSearchParams] = useState<SearchPageSegmentPagePathLog>()
    const { site } = useSite()

    const useFormMethods = useForm<SearchPageSegmentPagePathLog>()
    const { handleSubmit, setValue } = useFormMethods;

    // データ取得
    const { isPending, data: results } = useCVPageSegmentPagePathLogList(searchParams)
    useEffect(() => {
        if (data && data.length >= 20000) {
            setMessage('データが多すぎたため一部のデータのみ表示しています。絞り込み検索を行ってください。')
        }
    }, [data])

    useEffect(() => {
        if (results && results.data.length > 0) {
            setData(results.data)
        }

        if (results && results.conversions) {
            setConversions(results.conversions)
        }
    }, [results])

    const columnHelper = createColumnHelper<PageSegmentPagePathLog>()
    const columns = setColumns(columnHelper, conversions)

    useEffect(() => {
        if (site) {
            const startAt = new Date()
            startAt.setDate(startAt.getDate() - 30)
            
            setSearchParams({
                site_id: Number(site?.id),
                start_at: startAt.toISOString()
            })

            setValue('start_at', startAt.toISOString())
            setValue('page_segment_name', '')
            setValue('page_segment_name_search_type', undefined)
        }
    }, [site])

    const onSearch: SubmitHandler<SearchPageSegmentPagePathLog> = (data: SearchPageSegmentPagePathLog) => {
        if (site) {
            data.site_id = Number(site?.id)
        }

        setSearchParams(data)
        setMessage('') // 検索時にはデータ多すぎるメッセージを一旦リセットする
    }

    const csvColumns = createCsvColumns(conversions)

    if (!site) {
        return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    } else if (isPending) {
        return <Loading isLoading={isPending} />
    }
    return (
        <>        
            <Message message={message} />
            <H1 icon={<AssessmentIcon />}>[日次]コンバージョン別ページ分類xページパスレポート</H1>

            <div className="mt-2 grid grid-cols-2 gap-4">
                <Card sx={{ marginBottom: '38px' }}>
                    <CardContent sx={{ height: 300 }}>
                        <DailyPageSegmentPagePathSessionLineBarChart data={data} />
                    </CardContent>
                </Card>
                <Card sx={{ marginBottom: '38px' }}>
                    <CardContent sx={{ height: 300 }}>
                        <DailyPageSegmentPagePathConversionLineBarChart data={data} />
                    </CardContent>
                </Card>
            </div>

            <FormProvider {...useFormMethods}>
                <PaginationTable data={data} columns={columns} isSearchParts={true}>
                    <SearchModal onSubmit={handleSubmit(onSearch)} className="ml-2">

                        <Label isRequire={false} isNoMargin={true}>日付</Label>
                        <div className="flex justify-between items-center">
                            <DatePicker name="start_at" />
                            <span className="mr-2 ml-2">〜</span>
                            <DatePicker name="end_at" />
                        </div>

                        <Label isRequire={false}>ページ分類</Label>
                        <div>
                            <PageSegmentMultiSelect name="page_segment_ids" siteId={Number(site?.id)} />
                        </div>

                        <Label isRequire={false}>コンバージョン種別</Label>
                        <div className="flex">
                            <ConversionMultiSelect name="conversion_ids" siteId={Number(site?.id)} />
                        </div>
                    </SearchModal>
                    <CSVDownloadButton columns={csvColumns} data={data} filename="[日次]コンバージョン別ページ分類xページパスレポート" className="ml-2" />
                </PaginationTable>
            </FormProvider>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<PageSegmentPagePathLog>, conversions: { [key: string]: string} ): AccessorKeyColumnDef<PageSegmentPagePathLog, any>[] => {
    let columns = [
        columnHelper.accessor('date', {
            header: '日付',
            cell: (props) => (
                <div className="text-nowrap">{props.row.original.date}</div>
            )
        }),
        columnHelper.accessor('page_segment_name', {
            header: 'ページ分類',
            cell: (props) => (
                <div className="text-nowrap">{props.row.original.page_segment_name}</div>
            )
        }),
        columnHelper.accessor('sessions', {
            header: 'セッション数',
            cell: (props) => (
                <div className="text-right">{props.row.original.sessions.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('screen_page_views', {
            // header: 'ページビュー数'
            header: 'PV',
            cell: (props) => (
                <div className="text-right">{props.row.original.screen_page_views.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('screen_page_views_per_session', {
            // header: 'セッションあたりのページビュー数',
            header: 'PV/セッション',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.screen_page_views_per_session, 2).toFixed(2)}</div>
            ),
            meta: { isAverage: true }
        }),
        columnHelper.accessor('new_users', {
            header: '新規ユーザー数',
            cell: (props) => (
                <div className="text-right">{props.row.original.new_users.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('new_user_rate', {
            header: '新規ユーザー率',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.new_user_rate, 2).toFixed(2) + '%'}</div>
            ),
            meta: { isAverageRate: true }
        }),
        columnHelper.accessor('average_session_duration', {
            header: '平均滞在時間',
            cell: (props) => (
                <>
                    <div className="text-right">{secToTime(props.row.original.average_session_duration)}</div>
                </>
            ),
            meta: { isAverageTime: true }
        }),
        columnHelper.accessor('bounce_rate', {
            header: '離脱率',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.bounce_rate, 2).toFixed(2) + '%'}</div>
            ),
            meta: { isAverageRate: true }
        }),
        // columnHelper.accessor('conversions', {
        //     // header: 'コンバージョン数'
        //     header: 'CV',
        //     cell: (props) => (
        //         <div className="text-right">{props.row.original.conversions.toLocaleString()}</div>
        //     ),
        //     meta: { isSummable: true }
        // }),
        // columnHelper.accessor('conversion_rate', {
        //     // header: 'コンバージョン率',
        //     header: 'CVR',
        //     cell: (props) => (
        //         <div className="text-right">{roundWithScale(props.row.original.conversion_rate, 2).toFixed(2) + '%'}</div>
        //     ),
        //     meta: { isAverageRate: true }
        // }),
    ]

    // コンバージョンの数だけカラムを動的に追加
    const conversionColumns = createConversionColumns<PageSegmentPagePathLog>(columnHelper, conversions)
    columns = columns.concat(conversionColumns)

    return columns
}

const createCsvColumns = (conversions: { [key: string]: string }) => {
    const staticColumns = [
        { header: '日付', key: 'date' },
        { header: 'ページ分類', key: 'page_segment_name' },
        { header: 'セッション数', key: 'sessions' },
        { header: 'PV', key: 'screen_page_views' },
        { header: 'PV/セッション', key: 'screen_page_views_per_session', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) },
        { header: '新規ユーザー数', key: 'new_users' },
        { header: '新規ユーザー率', key: 'average_session_duration', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
        { header: '平均滞在時間', key: 'new_users', fn: (value: string) => secToTime(Number(value)) },
        { header: '離脱率', key: 'bounce_rate', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
    ]

    // コンバージョンの数だけカラムを動的に追加
    const conversionColumns = Object.entries(conversions).map(([key, label]) => ({
        header: `CV(${label})`,
        key: `CV_${key}`,
        fn: (value: string) => (Number(value) || 0).toLocaleString(),
    }))

    return [...staticColumns, ...conversionColumns]
}
