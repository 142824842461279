import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVGAPagePathLog, GAPagePathLog, SearchGAPagePathLog } from '../../Types/GAPagePathLog'


export const getGAPagePathLogList = async (searchParams: SearchGAPagePathLog | undefined) => {
    const { data } = await axios.get('/api/ga_page_path_logs', { params: searchParams })
    return data
}

export const useGAPagePathLogList = (searchParams: SearchGAPagePathLog | undefined): UseQueryResult<GAPagePathLog[]> => {
    return useQuery({
        queryKey: ['getGAPagePathLogList', searchParams],
        queryFn: () => getGAPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVGAPagePathLogList = async (searchParams: SearchGAPagePathLog | undefined) => {
    const { data } = await axios.get('/api/conversion_ga_page_path_logs', { params: searchParams })
    return data
}

export const useCVGAPagePathLogList = (searchParams: SearchGAPagePathLog | undefined): UseQueryResult<CVGAPagePathLog> => {
    return useQuery({
        queryKey: ['getCVGAPagePathLogList', searchParams],
        queryFn: () => getCVGAPagePathLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}