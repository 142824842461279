import axios, { AxiosResponse } from 'axios'
import { SearchConsoleSetting } from '../../Types/SearchConsoleSetting'

export const getSearchConsoleSetting = async (id: string) => {
    const { data } = await axios.get(`/api/search_console_settings/${id}`)
    return data
}

export const getSearchConsoleSettingBySiteId = async (site_id: string) => {
    const { data } = await axios.get(`/api/search_console_settings_site_id/${site_id}`)
    return data
}

export const updateSearchConsoleSetting = async (data: SearchConsoleSetting): Promise<AxiosResponse> => {
    return await axios.patch('/api/search_console_settings', data)
}

export const createSearchConsoleSetting = async (data: SearchConsoleSetting): Promise<AxiosResponse> => {
    return await axios.post('/api/search_console_settings', data)
}