import { FC, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { AddButton } from "../../../../Ui/Button"
import { getSite } from "../../../../../Hooks/User/Site"
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table"
import { PaginationTable } from "../../../../Ui/Table"
import { Site } from "../../../../../Types/Site"
import { Message } from "../../../../Ui/Message"
import { useConversionList } from "../../../../../Hooks/User/Conversion"
import { Conversion } from "../../../../../Types/Conversion"
import { format } from "date-fns"
import { Button, Chip } from "@mui/material"
import { Link } from "react-router-dom"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionList: FC = () => {
    const { state: message } = useLocation()

    const query = useQueryString()
    const siteId = query.get('site_id')

    if (!siteId) return <Page404 />

    const [site, setSite] = useState<Site>()


    useEffect(() => {
        const getData = async (siteId: string) => {
            const site = await getSite(siteId)
            setSite(site)
        }

        getData(siteId)
    }, [])


    // データ取得
    const { isPending, data } = useConversionList({ site_id: Number(site?.id) })

    const columnHelper = createColumnHelper<Conversion>()
    const columns = setColumns(columnHelper, siteId)

    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>
            <Message message={message} />
            <H1 className="mb-12" icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}コンバージョン設定）</H1>
            <PaginationTable data={data} columns={columns} isSearchParts={false}>
                <AddButton path={`/sites/conversions/create?site_id=${siteId}`} className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<Conversion>, siteId: string) => {
    return [
        columnHelper.accessor('name', {
            header: 'コンバージョン名',
        }),
        columnHelper.accessor('rules', {
            header: 'ルール',
            cell: (props) => (
                props.row.original.rules.map((rule, ruleIndex) => (
                    <div key={`div-${ruleIndex}`}>
                        <Chip 
                            label={rule.type === 'event' ? 'イベント' : 'URL'}
                            sx={{ 
                                fontSize: '10px',
                                height: '18px',
                                marginRight: '4px',
                                width: '4rem',
                            }}
                        />
                        {rule.data}
                    </div>
                ))
            )
        }),
        columnHelper.accessor('updated_at', {
            header: '最終更新日',
            cell: (props) => (
                format(props.row.original.updated_at, 'yyyy/MM/dd HH:mm')
            )
        }),
        columnHelper.display({
            id: 'action',
            header: '',
            cell: (props) => (
                <>
                    <Link to={`/sites/conversions/edit?site_id=${siteId}&id=${props.row.original.id}`}><Button>編集</Button></Link>
                </>
            )
        })
    ]
}