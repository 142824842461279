import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CVPageSegmentChannelPageLog, PageSegmentChannelPageLog, SearchPageSegmentChannelPageLog } from '../../Types/PageSegmentChannelPageLog'


export const getPageSegmentChannelPageLogList = async (searchParams: SearchPageSegmentChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/page_segment_channel_page_logs', { params: searchParams })
    return data
}

export const usePageSegmentChannelPageLogList = (searchParams: SearchPageSegmentChannelPageLog | undefined): UseQueryResult<PageSegmentChannelPageLog[]> => {
    return useQuery({
        queryKey: ['getPageSegmentChannelPageLogList', searchParams],
        queryFn: () => getPageSegmentChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}

export const getCVPageSegmentChannelPageLogList = async (searchParams: SearchPageSegmentChannelPageLog | undefined) => {
    const { data } = await axios.get('/api/conversion_page_segment_channel_page_logs', { params: searchParams })
    return data
}

export const useCVPageSegmentChannelPageLogList = (searchParams: SearchPageSegmentChannelPageLog | undefined): UseQueryResult<CVPageSegmentChannelPageLog> => {
    return useQuery({
        queryKey: ['getCVPageSegmentChannelPageLogList', searchParams],
        queryFn: () => getCVPageSegmentChannelPageLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}