import { FC, useEffect, useState } from "react"
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { useSite } from "../../../../Providers/Site"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Message } from "../../../Ui/Message"
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { PaginationTable } from "../../../Ui/Table"
import { roundWithScale } from "../../../../Utils/Math"
import { secToTime } from "../../../../Utils/Time"
import { Card, CardContent } from "@mui/material"
import { CSVDownloadButton } from "../../../Ui/Button"
import { SearchModal } from "../../../Molecules/SearchModal"
import { DatePicker, Input, Label } from "../../../Ui/Form"
import { SearchTypeSelect } from "../../../Molecules/SearchTypeSelect"
import { ConversionMultiSelect } from "../../../Molecules/ConversionSelect"
import { AllPagePathLog as AllPagePathLogType, SearchAllPagePathLog } from "../../../../Types/AllPagePathLog"
import { useAllPagePathLogList } from "../../../../Hooks/User/AllPagePathLog"
import { AllLogHorizontalBarChart } from "../../../Molecules/AllLogEChart"
import { PageSegmentMultiSelect } from "../../../Molecules/PageSegmentSelect"
import { UrlTooltip } from "../../../Molecules/Tooltip"
import { Site } from "../../../../Types/Site"

export const AllPagePathLog: FC = () => {
    const [message, setMessage] = useState('')

    const [searchParams, setSearchParams] = useState<SearchAllPagePathLog>()
    const { site } = useSite()

    const useFormMethods = useForm<SearchAllPagePathLog>()
    const { handleSubmit, setValue } = useFormMethods;

    // データ取得
    const { isPending, data } = useAllPagePathLogList(searchParams)
    useEffect(() => {
        if (data && data.length >= 20000) {
            setMessage('データが多すぎたため一部のデータのみ表示しています。絞り込み検索を行ってください。')
        }
    }, [data])

    const columnHelper = createColumnHelper<AllPagePathLogType>()
    const columns = setColumns(columnHelper, site)

    useEffect(() => {
        if (site) {
            setSearchParams({site_id: Number(site?.id)})
        }
    }, [site])

    const onSearch: SubmitHandler<SearchAllPagePathLog> = (data: SearchAllPagePathLog) => {
        if (site) {
            data.site_id = Number(site?.id)
        }

        setSearchParams(data)
        setMessage('') // 検索時にはデータ多すぎるメッセージを一旦リセットする
    }

    if (!site) {
        return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    } else if (isPending) {
        return <Loading isLoading={isPending} />
    }
    return (
        <>        
            <Message message={message} />
            <H1 icon={<AssessmentIcon />}>[全期間]ページパス レポート</H1>

            <div className="mt-2 grid grid-cols-2 gap-4">
                <Card sx={{ marginBottom: '38px' }}>
                    <CardContent sx={{ height: 300 }}>
                        <AllLogHorizontalBarChart data={data} valueTitle="セッション数" valueColumn="sessions" labelColumn="page_path_plus_query_string" limit={10} />
                    </CardContent>
                </Card>
                <Card sx={{ marginBottom: '38px' }}>
                    <CardContent sx={{ height: 300 }}>
                        <AllLogHorizontalBarChart data={data} valueTitle="CV数" valueColumn="conversions" labelColumn="page_path_plus_query_string" limit={10} />
                    </CardContent>
                </Card>
            </div>

            <FormProvider {...useFormMethods}>
                <PaginationTable data={data} columns={columns} isSearchParts={true} sort={{ id: 'sessions', desc: true }}>
                    <SearchModal onSubmit={handleSubmit(onSearch)} className="ml-2">

                        <Label isRequire={false} isNoMargin={true}>日付</Label>
                        <div className="flex justify-between items-center">
                            <DatePicker name="start_at" />
                            <span className="mr-2 ml-2">〜</span>
                            <DatePicker name="end_at" />
                        </div>

                        <Label isRequire={false}>ページパス</Label>
                        <div className="flex">
                            <Input name="page_path_plus_query_string" type="text" />
                            <SearchTypeSelect name="page_path_plus_query_string_search_type" />
                        </div>

                        <Label isRequire={false}>ページ分類</Label>
                        <div>
                            <PageSegmentMultiSelect name="page_segment_ids" siteId={Number(site?.id)} />
                        </div>

                        <Label isRequire={false}>コンバージョン種別</Label>
                        <div className="flex">
                            <ConversionMultiSelect name="conversion_ids" siteId={Number(site?.id)} />
                        </div>
                    </SearchModal>
                    <CSVDownloadButton columns={csvColumns} data={data} filename="[全期間]ページパスレポート" className="ml-2" />
                </PaginationTable>
            </FormProvider>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<AllPagePathLogType>, site: Site | null) => {
    return [
        columnHelper.accessor('page_path_plus_query_string', {
            header: 'ページパス',
            cell: (props) => (
                <>
                    {site && site.id ? (
                        <a href={site.url + props.row.original.page_path_plus_query_string} target="_blank">
                            <p className="page-title">{props.row.original.page_title}</p>
                            <UrlTooltip url={props.row.original.page_path_plus_query_string} />
                        </a>
                    ) : (
                        <>
                            <p className="page-title">{props.row.original.page_title}</p>
                            <UrlTooltip url={props.row.original.page_path_plus_query_string} />
                        </>
                    )}
                </>
            )
        }),
        columnHelper.accessor('sessions', {
            header: 'セッション数',
            cell: (props) => (
                <div className="text-right">{props.row.original.sessions.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('screen_page_views', {
            // header: 'ページビュー数'
            header: 'PV',
            cell: (props) => (
                <div className="text-right">{props.row.original.screen_page_views.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('screen_page_views_per_session', {
            // header: 'セッションあたりのページビュー数',
            header: 'PV/セッション',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.screen_page_views_per_session, 2).toFixed(2)}</div>
            ),
            meta: { isAverage: true }
        }),
        columnHelper.accessor('new_users', {
            header: '新規ユーザー数',
            cell: (props) => (
                <div className="text-right">{props.row.original.new_users.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('new_user_rate', {
            header: '新規ユーザー率',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.new_user_rate, 2).toFixed(2) + '%'}</div>
            ),
            meta: { isAverageRate: true }
        }),
        columnHelper.accessor('average_session_duration', {
            header: '平均滞在時間',
            cell: (props) => (
                <>
                    <div className="text-right">{secToTime(props.row.original.average_session_duration)}</div>
                </>
            ),
            meta: { isAverageTime: true }
        }),
        columnHelper.accessor('bounce_rate', {
            header: '離脱率',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.bounce_rate, 2).toFixed(2) + '%'}</div>
            ),
            meta: { isAverageRate: true }
        }),
        columnHelper.accessor('conversions', {
            // header: 'コンバージョン数'
            header: 'CV',
            cell: (props) => (
                <div className="text-right">{props.row.original.conversions.toLocaleString()}</div>
            ),
            meta: { isSummable: true }
        }),
        columnHelper.accessor('conversion_rate', {
            // header: 'コンバージョン率',
            header: 'CVR',
            cell: (props) => (
                <div className="text-right">{roundWithScale(props.row.original.conversion_rate, 2).toFixed(2) + '%'}</div>
            ),
            meta: { isAverageRate: true }
        }),
    ]
}

const csvColumns = [
    { header: 'ページパス', key: 'page_path_plus_query_string' },
    { header: 'タイトル', key: 'page_title' },
    { header: 'セッション数', key: 'sessions' },
    { header: 'PV', key: 'screen_page_views' },
    { header: 'PV/セッション', key: 'screen_page_views_per_session', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) },
    { header: '新規ユーザー数', key: 'new_users' },
    { header: '新規ユーザー率', key: 'average_session_duration', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
    { header: '平均滞在時間', key: 'new_users', fn: (value: string) => secToTime(Number(value)) },
    { header: '離脱率', key: 'bounce_rate', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
    { header: 'CV', key: 'conversions' },
    { header: 'CVR', key: 'conversion_rate', fn: (value: string) => roundWithScale(Number(value), 2).toFixed(2) + '%' },
]