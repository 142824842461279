import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../../Ui/Loading"
import { H1 } from "../../../../Ui/H1"
import { MainContent } from "../../../../Ui/MainContent"
import { Input, Label } from "../../../../Ui/Form"
import { SubmitButton } from "../../../../Ui/Button"
import { Site } from "../../../../../Types/Site"
import { getSite } from "../../../../../Hooks/User/Site"
import { ConversionPagePathSetting } from "../../../../../Types/ConversionPagePathSetting"
import { createConversionPagePathSetting } from "../../../../../Hooks/User/ConversionPagePathSetting"
import { PageTypeSelect } from "../../../../Molecules/PageTypeSelect"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const ConversionPagePathSettingCreate: FC = () => {

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [site, setSite] = useState<Site>()

    const useFormMethods = useForm<ConversionPagePathSetting>()
    const { handleSubmit, setValue, setError } = useFormMethods

    const onSubmit: SubmitHandler<ConversionPagePathSetting> = (data) => {
        setIsLoading(true)

        createConversionPagePathSetting(data)
            .then(() => {
                navigate(`/sites/conversion_page_path_settings?id=${id}`, { state: 'フォームを設定しました' })
            })
            .catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.site_id) setError('site_id', { type: 'custom', message: errors.site_id.join('/') })
                if (errors.page_path) setError('page_path', { type: 'custom', message: errors.page_path.join('/') })
                if (errors.page_type_id) setError('page_type_id', { type: 'custom', message: errors.page_type_id.join('/') })
            })
    }

    useEffect(() => {
        const getData = async (id: string) => {
            const site = await getSite(id)
            setSite(site)
            setValue('site_id', site.id)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}フォーム設定）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label isRequire={true}>ページパス</Label>
                        <Input name="page_path" type="text" />
                        
                        <Label isRequire={true}>フォームの種類</Label>
                        <PageTypeSelect name="page_type_id" />

                        <Input name="site_id" type="hidden" />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}