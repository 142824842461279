import { useEffect, useState } from "react"
import { roundWithScale } from "../../Utils/Math"
import { Box, Button, Card, CardContent, Typography } from "@mui/material"
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Link } from "react-router-dom"
import { CenterCircularProgress } from "../Ui/Progress"
import { CenterBox } from "../Ui/Box"
import { useAnnualMonthlySummaryLogList } from "../../Hooks/User/MonthlySummaryLog"
import { SearchAnnualMonthlySummaryLog } from "../../Types/MonthlySummaryLog"
import { useSite } from "../../Providers/Site"
import { LinerRateBarChartWithLabel, MonthlyAreaChart, MonthlyOnlyLastStackBarChart } from "../Ui/Chart"
import { useThisMonthTarget } from "../../Hooks/User/MonthlyTarget"
import { getThisMonth } from "../../Utils/Date"
import { useThisMonthChannelGroupLogList } from "../../Hooks/User/MonthlyChannelGroupLog"
import { useThisMonthMonthlyDeviceCategoryLogList } from "../../Hooks/User/MonthlyDeviceCategoryLog"
import { SearchMonthlyDeviceCategoryLog } from "../../Types/MonthlyDeviceCategoryLog"
import { EChartPie } from "../Ui/EChart"
import { getOpenAiLog } from "../../Hooks/User/OpenAiLog"
import { MarkdownViewer } from "../Ui/Markdown"
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import { useSearchConsoleThisMonthTop5QueryList } from "../../Hooks/User/SearchConsole"
import { SearchConsoleLogProps } from "../../Types/SearchConsole"

export const OpenAiLogMessage = () => {
    const { site } = useSite()
    const [message, setMessage] = useState('')

    useEffect(() => {
        const getData = async (id: string) => {
            const openAiLog = await getOpenAiLog(id)
            setMessage(openAiLog.message)

            console.log(openAiLog)
        }

        getData(String(site?.id))
    }, [site])

    if (!message) return ''
    return (
        <Card>
            <CardContent sx={{ height: '100%' }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                    <ContentPasteSearchIcon sx={{ position: 'relative', top: -2, marginRight: '4px' }} />
                    AI診断
                </Typography>
                <MarkdownViewer markdown={message} />
            </CardContent>
        </Card>
    )
}

export const MonthlySessionBarChart = () => {
    const [data, setData] = useState<{ yearMonth: string, value1: number, value2: number }[]>()
    const [yearOverYearChangeRate, setYearOverYearChangeRate] = useState(0)
    const [lastYearSessions, setLastYearSessions] = useState(0)
    const [thisMonthSessions, setThisMonthSessions] = useState(0)
    const [targetSessions, setTargetSessions] = useState(0)
    const [targetAchievementRatio, setTargetAchievementRatio] = useState(0)
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()
    const thisMonth = getThisMonth()

    // データ取得
    const { isPending, data: annualMonthlyLogs } = useAnnualMonthlySummaryLogList(searchParams)
    const { isPending: isTargetPending, data: thisMonthTarget } = useThisMonthTarget(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})

        // プロパティを切り替えたら一度削除しておく
        setData([])
        setYearOverYearChangeRate(0)
        setLastYearSessions(0)
        setThisMonthSessions(0)
        setTargetSessions(0)
        setTargetAchievementRatio(0)
    }, [site])

    useEffect(() => {
        if (annualMonthlyLogs && annualMonthlyLogs.data.length > 0) {
            if (annualMonthlyLogs.lastYearLog && annualMonthlyLogs.lastYearLog.sessions > 0 && annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.sessions > 0) {
                setYearOverYearChangeRate(Math.round(((annualMonthlyLogs.thisMonthLog.sessions + annualMonthlyLogs.estimatedSessions) / annualMonthlyLogs.lastYearLog.sessions) * 100))
            }

            if (annualMonthlyLogs.lastYearLog && annualMonthlyLogs.lastYearLog.sessions > 0) {
                setLastYearSessions(annualMonthlyLogs.lastYearLog.sessions)
            }

            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.sessions > 0) {
                setThisMonthSessions(annualMonthlyLogs.thisMonthLog.sessions)
            }

            // Chartコンポーネントで使用できるよう加工
            setData(annualMonthlyLogs.data.map((row, index) => {
                if (row.year_month === thisMonth) {
                    return {
                        yearMonth: row.year_month,
                        value1: row.sessions,
                        value2: annualMonthlyLogs.estimatedSessions,
                    }
                }
                return {
                    yearMonth: row.year_month,
                    value1: 0,
                    value2: row.sessions,
                }
            }))
        }
    }, [annualMonthlyLogs])

    useEffect(() => {
        if (thisMonthTarget && thisMonthTarget.sessions > 0) {
            setTargetSessions(thisMonthTarget.sessions)

            if (annualMonthlyLogs && annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.sessions > 0) {
                setTargetAchievementRatio(roundWithScale(((annualMonthlyLogs.thisMonthLog.sessions + annualMonthlyLogs.estimatedSessions) / thisMonthTarget.sessions) * 100, 0))
            }
        }
    }, [thisMonthTarget, annualMonthlyLogs])

    return (
        <Card>
            <CardContent sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                        <DirectionsRunIcon sx={{ position: 'relative', top: -2 }} />
                        セッション数
                    </Typography>
                    <Link to={`/analytics/monthly_summary_logs`}><Button>詳細をみる</Button></Link>
                </Box>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Typography sx={{ fontSize: 36, fontWeight: 777, textAlign: 'center' }}>{thisMonthSessions.toLocaleString()}</Typography>

                        <MonthlyOnlyLastStackBarChart data={data} tooltipLabel="セッション" baseColor="#1576db" stackColor="#53d0c6" targetYearMonth={thisMonth} />

                        {!isTargetPending && thisMonthTarget && thisMonthTarget.sessions ? (
                            <>
                                <div className="font-bold -mb-2">対目標: {targetSessions.toLocaleString()}</div>
                                <LinerRateBarChartWithLabel rate={targetAchievementRatio} height={5} labelTop={1} />
                            </>
                        ) : (
                            <div>目標設定されていません</div>
                        )}
                        

                        <div className="font-bold -mb-2 mt-4">対昨年: {lastYearSessions.toLocaleString()}</div>
                        <LinerRateBarChartWithLabel rate={yearOverYearChangeRate} height={5} labelTop={1} mainColor="#53d0c6"  />
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlyConversionBarChart = () => {
    const [data, setData] = useState<{ yearMonth: string, value1: number, value2: number }[]>()
    const [yearOverYearChangeRate, setYearOverYearChangeRate] = useState(0)
    const [lastYearConversions, setLastYearConversions] = useState(0)
    const [thisMonthConversions, setThisMonthConversions] = useState(0)
    const [targetConversions, setTargetConversions] = useState(0)
    const [targetAchievementRatio, setTargetAchievementRatio] = useState(0)
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()
    const thisMonth = getThisMonth()

    // データ取得
    const { isPending, data: annualMonthlyLogs } = useAnnualMonthlySummaryLogList(searchParams)
    const { isPending: isTargetPending, data: thisMonthTarget } = useThisMonthTarget(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})
    
        // プロパティを切り替えたら一度削除しておく
        setData([])
        setYearOverYearChangeRate(0)
        setLastYearConversions(0)
        setThisMonthConversions(0)
        setTargetConversions(0)
        setTargetAchievementRatio(0)
    }, [site])

    useEffect(() => {
        if (annualMonthlyLogs && annualMonthlyLogs.data.length > 0) {
            if (annualMonthlyLogs.lastYearLog && annualMonthlyLogs.lastYearLog.conversions > 0 && annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.conversions > 0) {
                setYearOverYearChangeRate(Math.round(((annualMonthlyLogs.thisMonthLog.conversions + annualMonthlyLogs.estimatedConversions) / annualMonthlyLogs.lastYearLog.conversions) * 100))
            }

            if (annualMonthlyLogs.lastYearLog && annualMonthlyLogs.lastYearLog.conversions > 0) {
                setLastYearConversions(annualMonthlyLogs.lastYearLog.conversions)
            }

            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.conversions > 0) {
                setThisMonthConversions(annualMonthlyLogs.thisMonthLog.conversions)
            }

            // Chartコンポーネントで使用できるよう加工
            setData(annualMonthlyLogs.data.map((row, index) => {
                if (row.year_month === thisMonth) {
                    return {
                        yearMonth: row.year_month,
                        value1: row.conversions,
                        value2: annualMonthlyLogs.estimatedConversions,
                    }
                }
                return {
                    yearMonth: row.year_month,
                    value1: 0,
                    value2: row.conversions,
                }
            }))
        }
    }, [annualMonthlyLogs])

    useEffect(() => {
        if (thisMonthTarget && thisMonthTarget.conversions > 0) {
            setTargetConversions(thisMonthTarget.conversions)

            if (annualMonthlyLogs && annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.conversions > 0) {
                setTargetAchievementRatio(roundWithScale(((annualMonthlyLogs.thisMonthLog.conversions + annualMonthlyLogs.estimatedConversions) / thisMonthTarget.conversions) * 100, 0))
            }
        }
    }, [thisMonthTarget, annualMonthlyLogs])

    return (
        <Card>
            <CardContent sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                        <ShoppingCartIcon sx={{ position: 'relative', top: -2 }} />
                        CV数
                    </Typography>
                    <Link to={`/analytics/monthly_summary_logs`}><Button>詳細をみる</Button></Link>
                </Box>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Typography sx={{ fontSize: 36, fontWeight: 777, textAlign: 'center' }}>{thisMonthConversions.toLocaleString()}</Typography>

                        <MonthlyOnlyLastStackBarChart data={data} tooltipLabel="CV" baseColor="#f9837c" stackColor="#1576db" targetYearMonth={thisMonth} />

                        {!isTargetPending && thisMonthTarget && thisMonthTarget.sessions ? (
                            <>
                                <div className="font-bold -mb-2">対目標: {targetConversions.toLocaleString()}</div>
                                <LinerRateBarChartWithLabel rate={targetAchievementRatio} height={5} labelTop={1} mainColor="#f9837c" />
                            </>
                        ) : (
                            <div>目標設定されていません</div>
                        )}

                        <div className="font-bold -mb-2 mt-4">対昨年: {lastYearConversions.toLocaleString()}</div>
                        <LinerRateBarChartWithLabel rate={yearOverYearChangeRate} height={5} labelTop={1} mainColor="#53d0c6"  />
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

// フォーム遷移率パーツ
export const MonthlyFormTransitionRateAreaChart = () => {
    const [data, setData] = useState<{ yearMonth: string, value: number }[]>()
    const [thisMonthFormTransitionCount, setThisMonthFormTransitionCount] = useState(0)
    const [thisMonthFormTransitionRate, setThisMonthFormTransitionRate] = useState(0)
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data: annualMonthlyLogs } = useAnnualMonthlySummaryLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})
        
        // プロパティを切り替えたら一度削除しておく
        setData([])
        setThisMonthFormTransitionCount(0)
        setThisMonthFormTransitionRate(0)
    }, [site])

    useEffect(() => {
        if (annualMonthlyLogs && annualMonthlyLogs.data.length > 0) {
            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.input_count > 0) {
                setThisMonthFormTransitionCount(annualMonthlyLogs.thisMonthLog.input_count)
            }

            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.input_rate > 0) {
                setThisMonthFormTransitionRate(roundWithScale(annualMonthlyLogs.thisMonthLog.input_rate, 2))
            }

            setData(annualMonthlyLogs.data.map((row) => {
                return {
                    yearMonth: row.year_month,
                    value: roundWithScale(row.input_rate, 2),
                }
            }))
        }
    }, [annualMonthlyLogs])

    return (
        <Card style={{ width: '100%', height: 200 }}>
            <CardContent style={{ width: '100%', height: 150 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>フォーム遷移</Typography>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Typography sx={{ fontSize: 36, fontWeight: 777, textAlign: 'center' }}>{thisMonthFormTransitionCount} / {thisMonthFormTransitionRate}%</Typography>
                        <MonthlyAreaChart data={data} label="遷移率" isRate={true} />
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

// フォーム完了率パーツ
export const MonthlyFormCompletionRateAreaChart = () => {
    const [data, setData] = useState<{ yearMonth: string, value: number }[]>()
    const [thisMonthFormCompletionCount, setThisMonthFormCompletionCount] = useState(0)
    const [thisMonthFormCompletionRate, setThisMonthFormCompletionRate] = useState(0)
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data: annualMonthlyLogs } = useAnnualMonthlySummaryLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})
        
        // プロパティを切り替えたら一度削除しておく
        setData([])
        setThisMonthFormCompletionCount(0)
        setThisMonthFormCompletionRate(0)
    }, [site])

    useEffect(() => {
        if (annualMonthlyLogs && annualMonthlyLogs.data.length > 0) {
            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.thanks_count > 0) {
                setThisMonthFormCompletionCount(annualMonthlyLogs.thisMonthLog.thanks_count)
            }

            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.thanks_rate > 0) {
                setThisMonthFormCompletionRate(roundWithScale(annualMonthlyLogs.thisMonthLog.thanks_rate, 2))
            }

            setData(annualMonthlyLogs.data.map((row) => {
                return {
                    yearMonth: row.year_month,
                    value: roundWithScale(row.thanks_rate, 2),
                }
            }))
        }
    }, [annualMonthlyLogs])

    return (
        <Card style={{ width: '100%', height: 200 }}>
            <CardContent style={{ width: '100%', height: 150 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>フォーム完了率</Typography>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Typography sx={{ fontSize: 36, fontWeight: 777, textAlign: 'center' }}>{thisMonthFormCompletionCount} / {thisMonthFormCompletionRate}%</Typography>
                        <MonthlyAreaChart data={data} label="完了率" isRate={true} />
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlyConversionRateAreaChart = () => {
    const [data, setData] = useState<{ yearMonth: string, value: number }[]>()
    const [thisMonthConversionRate, setThisMonthConversionRate] = useState(0)
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data: annualMonthlyLogs } = useAnnualMonthlySummaryLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})
        
        // プロパティを切り替えたら一度削除しておく
        setData([])
        setThisMonthConversionRate(0)
    }, [site])

    useEffect(() => {
        if (annualMonthlyLogs && annualMonthlyLogs.data.length > 0) {
            if (annualMonthlyLogs.thisMonthLog && annualMonthlyLogs.thisMonthLog.conversion_rate > 0) {
                setThisMonthConversionRate(roundWithScale(annualMonthlyLogs.thisMonthLog.conversion_rate, 2))
            }

            setData(annualMonthlyLogs.data.map((row) => {
                return {
                    yearMonth: row.year_month,
                    value: roundWithScale(row.conversion_rate, 2),
                }
            }))
        }
    }, [annualMonthlyLogs])

    return (
        <Card style={{ width: '100%', height: 200 }}>
            <CardContent style={{ width: '100%', height: 150 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>CVR</Typography>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Typography sx={{ fontSize: 36, fontWeight: 777, textAlign: 'center' }}>{thisMonthConversionRate}%</Typography>
                        <MonthlyAreaChart data={data} label="CVR" isRate={true} />
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlyPageViewAreaChart = () => {
    const [data, setData] = useState<{ yearMonth: string, value: number }[]>()
    const [thisMonthPageView, setThisMonthPageView] = useState(0)
    const [thisMonthPageViewPerSession, setThisMonthPageViewPerSession] = useState(0)
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data: annualMonthlyLogs } = useAnnualMonthlySummaryLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})
        
        // プロパティを切り替えたら一度削除しておく
        setData([])
        setThisMonthPageView(0)
        setThisMonthPageViewPerSession(0)
    }, [site])

    useEffect(() => {
        if (annualMonthlyLogs && annualMonthlyLogs.data.length > 0) {
            if (annualMonthlyLogs.thisMonthLog) {
                if (annualMonthlyLogs.thisMonthLog.screen_page_views > 0) {
                    setThisMonthPageView(annualMonthlyLogs.thisMonthLog.screen_page_views)
                }
                if (annualMonthlyLogs.thisMonthLog.screen_page_views_per_session > 0) {
                    setThisMonthPageViewPerSession(roundWithScale(annualMonthlyLogs.thisMonthLog.screen_page_views_per_session, 2))
                }
            }


            setData(annualMonthlyLogs.data.map((row) => {
                return {
                    yearMonth: row.year_month,
                    value: row.screen_page_views,
                }
            }))
        }
    }, [annualMonthlyLogs])

    return (
        <Card style={{ width: '100%', height: 200 }}>
            <CardContent style={{ width: '100%', height: 150 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>PV数・平均PV数</Typography>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Typography sx={{ fontSize: 32, fontWeight: 777, textAlign: 'center' }}>
                            {thisMonthPageView.toLocaleString()} / {thisMonthPageViewPerSession}
                        </Typography>
                        <MonthlyAreaChart data={data} label="PV" />
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlyChannelBarChart = () => {
    const [thisMonthChannelLogs, setThisMonthChannelLogs] = useState<{ channelGroup: string, sessionRate: number, conversionRate: number }[]>()
    const [searchParams, setSearchParams] = useState<SearchAnnualMonthlySummaryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data } = useThisMonthChannelGroupLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})

        setThisMonthChannelLogs([])
    }, [site])

    useEffect(() => {
        if (data && data.data) {
            setThisMonthChannelLogs(data.data.map((row, index) => {
                return {
                    channelGroup: row.session_default_channel_group,
                    sessionRate: data.totalSessions ? roundWithScale((row.sessions / data.totalSessions) * 100, 2) : 0,
                    conversionRate: data.totalConversions ? roundWithScale((row.conversions / data.totalConversions) * 100, 2) : 0,
                }
            }))
        }
    }, [data])

    return (
        <Card>
            <CardContent sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                        集客チャネル別
                    </Typography>
                    <Link to={`/analytics/monthly_channel_group_logs`}><Button>詳細をみる</Button></Link>
                </Box>

                {isPending ? (
                    <CenterCircularProgress />
                ) : thisMonthChannelLogs && thisMonthChannelLogs.length > 0 ? (
                    <>
                        <Box sx={{ marginTop: 4, display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: 4, borderBottom: '1px solid #efefef', padding: '0 10px' }} >
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>集客経路</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>セッション</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>CV</Typography>
                        </Box>

                        {thisMonthChannelLogs?.map((item, index) => (
                            <Box key={index} sx={{ marginTop: 2, display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: 4, padding: '0 10px' }} >
                                <Typography sx={{ fontSize: 14, fontWeight: 600, lineHeight: 2 }}>{item.channelGroup}</Typography>
                                <LinerRateBarChartWithLabel rate={item.sessionRate} gap={10} bgColor="#d0e1f8" />
                                <LinerRateBarChartWithLabel rate={item.conversionRate} gap={10} bgColor="#d0e1f8" />
                            </Box>                    
                        ))}
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlyDeviceSessionPieChart = () => {
    const [thisMonthDeviceCategoryLog, setThisMonthDeviceCategoryLogs] = useState<{ name: string, value: number }[]>()
    const [searchParams, setSearchParams] = useState<SearchMonthlyDeviceCategoryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data } = useThisMonthMonthlyDeviceCategoryLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})

        // プロパティを切り替えたときはリセットする
        setThisMonthDeviceCategoryLogs([])
    }, [site])

    useEffect(() => {
        if (data && data.length > 0) {
            setThisMonthDeviceCategoryLogs(data.map((row, index) => {
                return {
                    name: row.device_category,
                    value: row.sessions
                }
            }))
        }
    }, [data])

    return (
        <Card>
            <CardContent sx={{ height: 300 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                    デバイス別セッション割合
                </Typography>

                {isPending ? (
                    <CenterCircularProgress />
                ) : thisMonthDeviceCategoryLog && thisMonthDeviceCategoryLog.length > 0 ? (
                    <EChartPie name="セッション数" data={thisMonthDeviceCategoryLog}/>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlyDeviceConversionPieChart = () => {
    const [thisMonthDeviceCategoryLog, setThisMonthDeviceCategoryLogs] = useState<{ name: string, value: number }[]>()
    const [searchParams, setSearchParams] = useState<SearchMonthlyDeviceCategoryLog>()
    const { site } = useSite()

    // データ取得
    const { isPending, data } = useThisMonthMonthlyDeviceCategoryLogList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})

        // プロパティを切り替えたときはリセットする
        setThisMonthDeviceCategoryLogs([])
    }, [site])

    useEffect(() => {
        if (data && data.length > 0) {
            setThisMonthDeviceCategoryLogs(data.map((row, index) => {
                return {
                    name: row.device_category,
                    value: row.conversions
                }
            }))
        }
    }, [data])

    return (
        <Card>
            <CardContent sx={{ height: 300 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                    デバイス別CV割合
                </Typography>

                {isPending ? (
                    <CenterCircularProgress />
                ) : thisMonthDeviceCategoryLog && thisMonthDeviceCategoryLog.length > 0 ? (
                    <EChartPie name="CV数" data={thisMonthDeviceCategoryLog}/>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}

export const MonthlySearchConsoleTop5Query = () => {
    const { site } = useSite()
    const [searchParams, setSearchParams] = useState<SearchConsoleLogProps>()

    const { isPending, data } = useSearchConsoleThisMonthTop5QueryList(searchParams)

    useEffect(() => {
        setSearchParams({site_id: Number(site?.id)})
    }, [site])    

    return (
        <Card>
            <CardContent sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 777 }}>
                        検索ワードTOP5
                    </Typography>
                </Box>

                {isPending ? (
                    <CenterCircularProgress />
                ) : data && data.length > 0 ? (
                    <>
                        <Box sx={{ marginTop: 4, display: 'grid', gridTemplateColumns: '3fr 1fr 1fr 1fr 1fr', gap: 4, borderBottom: '1px solid #efefef', padding: '0 10px' }} >
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>検索ワード</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>クリック数</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>表示回数</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>CTR</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 600 }}>順位</Typography>
                        </Box>

                        {data?.map((item, index) => (
                            <Box key={index} sx={{ marginTop: 2, display: 'grid', gridTemplateColumns: '3fr 1fr 1fr 1fr 1fr', gap: 4, padding: '0 10px' }} >
                                <Typography sx={{ fontSize: 14, lineHeight: 2 }}>{item.query}</Typography>
                                <Typography sx={{ fontSize: 14, textAlign: 'center', lineHeight: 2 }}>{item.clicks}</Typography>
                                <Typography sx={{ fontSize: 14, textAlign: 'center', lineHeight: 2 }}>{item.impressions}</Typography>
                                <Typography sx={{ fontSize: 14, textAlign: 'center', lineHeight: 2 }}>{roundWithScale(item.ctr * 100, 2)} %</Typography>
                                <Typography sx={{ fontSize: 14, textAlign: 'center', lineHeight: 2 }}>{roundWithScale(item.position, 1)}</Typography>
                            </Box>                    
                        ))}
                    </>
                ) : (
                    <CenterBox>No Data</CenterBox>
                )}
            </CardContent>
        </Card>
    )
}