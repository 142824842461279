import { FC, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { format } from 'date-fns' 
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import { Loading } from "../../../Ui/Loading"
import { Message } from "../../../Ui/Message"
import { H1 } from "../../../Ui/H1"
import { PaginationTable } from "../../../Ui/Table"
import { CreateButton, CSVDownloadButton } from "../../../Ui/Button"
import { usePageSegmentList } from "../../../../Hooks/User/PageSegment"
import { PageSegment, SearchPageSegment } from "../../../../Types/PageSegment"
import TagIcon from '@mui/icons-material/Tag'
import { useSite } from "../../../../Providers/Site"
import { RuleRenderer } from "../../../Molecules/PageSegmentRule"

export const PageSegmentList: FC = () => {
    const { state: message } = useLocation()
    const { site } = useSite()
    const [searchParams, setSearchParams] = useState<SearchPageSegment>()

    // データ取得
    const { isPending, data } = usePageSegmentList(searchParams)

    const columnHelper = createColumnHelper<PageSegment>()
    const columns = setColumns(columnHelper)

    useEffect(() => {
        if (site) {
            setSearchParams({site_id: Number(site?.id)})
        }
    }, [site])

    if (!site) {
        return <Message message="ヘッダーのプルダウンより対象のサイトを選択してください" />
    }
    if (isPending) return <Loading isLoading={isPending} />
    return (
        <>        
            <Message message={message} />
            <H1 icon={<TagIcon />}>ページ分類</H1>
            
            <PaginationTable data={data} columns={columns}>
                <CreateButton path="/page_segments/create" className="ml-2" />
                <CSVDownloadButton columns={csvColumns} data={data} filename="ページ分類" className="ml-2" />
            </PaginationTable>
        </>
    )
}

const setColumns = (columnHelper: ColumnHelper<PageSegment>) => {
    return [
        columnHelper.accessor('name', {
            header: 'ページ分類名'
        }),
        columnHelper.accessor('rule', {
            header: 'ルール',
            cell: (props) => (
                <RuleRenderer rule={props.row.original.rule} maxHeight={62} />
            )
        }),
        columnHelper.accessor('updated_at', {
            header: '最終更新日',
            cell: (props) => (
                format(props.row.original.updated_at, 'yyyy/MM/dd HH:mm')
            )
        }),
        columnHelper.display({
            id: 'action',
            header: '',
            cell: (props) => (
                <>
                    <Link to={`/page_segments/edit?id=${props.row.original.id}`}><Button>編集</Button></Link>
                </>
            )
        })
    ]
}

const csvColumns = [
    { header: 'ページ分類名', key: 'name' },
    { header: 'ルール', key: 'rule', fn: (value: []) => getFormattedRule(value) },
    { header: '最終更新日', key: 'updated_at', fn: (value: string) => format(value, 'yyyy/MM/dd HH:mm') },
]

const getFormattedRule = (rule: { path: string, type: string }[][]) => {
    const formattedRule = rule.map((andRule) => (
        andRule.map((orRule) => (
            `${orRule.path}(${orRule.type})`
        )).join(' または\n')
    )).join('\nかつ\n')

    return formattedRule
}