import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { Input, Label } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { SearchConsoleSetting as SearchConsoleSettingType } from "../../../../Types/SearchConsoleSetting"
import { SearchConsoleSiteUrlSelect } from "../../../Molecules/SearchConsoleSiteUrlSelect"
import { createSearchConsoleSetting, getSearchConsoleSettingBySiteId, updateSearchConsoleSetting } from "../../../../Hooks/User/SearchConsoleSetting"
import { Site } from "../../../../Types/Site"
import { getSite } from "../../../../Hooks/User/Site"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const SearchConsoleSetting: FC = () => {
    const [site, setSite] = useState<Site>()

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    const useFormMethods = useForm<SearchConsoleSettingType>()
    const { handleSubmit, setValue, setError } = useFormMethods

    const onSubmit: SubmitHandler<SearchConsoleSettingType> = (data) => {
        setIsLoading(true)

        if (data.id) {
            updateSearchConsoleSetting(data)
                .then(() => {
                    navigate('/sites', { state: 'GoogleSearchConsole連携が完了しました' })
                })
                .catch((error) => {
                    setIsLoading(false)
                    const errors = error.response.data.errors
                    if (errors.url) setError('url', { type: 'custom', message: errors.url.join('/') })
                })
        } else {
            createSearchConsoleSetting(data)
                .then(() => {
                    navigate('/sites', { state: 'GoogleSearchConsole連携が完了しました' })
                })
                .catch((error) => {
                    setIsLoading(false)
                    const errors = error.response.data.errors
                    if (errors.url) setError('url', { type: 'custom', message: errors.url.join('/') })
                })
        }
    }
    
    useEffect(() => {
        const getData = async (id: string) => {
            const data = await getSearchConsoleSettingBySiteId(id)
            const site = await getSite(id)

            setSite(site)
            setValue('site_id', Number(id))
            setValue('id', data.id)
            setValue('url', data.url)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定（{site && site.name + ': '}GoogleSearchConsole連携）</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input name="id" type="hidden" disabled={true} />
                        <Input name="site_id" type="hidden" disabled={true} />

                        <Label isRequire={true}>権限のあるSearchConsoleサイトURL</Label>
                        <SearchConsoleSiteUrlSelect site_id={id} name="url" className="w-auto" />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}