import { SearchConsoleLog } from "../../Types/SearchConsole"
import ReactECharts from 'echarts-for-react'
import { roundWithScale } from "../../Utils/Math"

export const DailyLineChart = ({ data }: { data: SearchConsoleLog[] | undefined }) => {
    if (!data) return ''

    // dataを日付順に並べ替え
    const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    const xAxisData = sortedData.map(item => item.date)
    const clickData = sortedData.map(item => item.clicks)
    const impressionData = sortedData.map(item => item.impressions)
    const ctrData = sortedData.map(item => roundWithScale(item.ctr, 2))
    const positionData = sortedData.map(item => roundWithScale(item.position, 1))

    const option = {
        tooltip: {
            trigger: 'axis',
            confine: false,  // カード内にツールチップを閉じ込めないようにする
            appendToBody: true,  // bodyにツールチップを追加する
            axisPointer: {
                type: 'shadow',
            },
            // formatter: (params: any) => {
            //     const tooltipContent = params.map((item: any) => {
            //         let value = item.value
            //         if (item.seriesName === 'CTR') {
            //             value = `${item.value}%`
            //         }
            //         return `${item.marker} ${item.seriesName}: ${value}`
            //     }).join('<br />')

            //     return `${params[0].axisValue}<br/>${tooltipContent}`
            // }
        },
        legend: {
            data: ['クリック数', '表示回数', 'CTR', '平均掲載順位'],
            top: 'bottom',  // グラフの下に配置
            itemWidth: 14,  // レジェンドアイテムの幅
            itemHeight: 10,  // レジェンドアイテムの高さ
            itemGap: 10,  // 各アイテム間の余白
        },
        grid: {
            left: '3%', // 余白の調整
            right: '3%',
            bottom: '20%',
            top: '15%',
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
        },
        yAxis: [
            {
                type: 'value',
                axisLabel: { show: false }, // Y軸ラベルを非表示
                splitLine: { show: false }, // 横線を非表示
            },
            {
                type: 'value',
                axisLabel: { show: false }, // Y軸ラベルを非表示
                splitLine: { show: false }, // 横線を非表示
            },
            {
                type: 'value',
                axisLabel: { show: false }, // Y軸ラベルを非表示
                splitLine: { show: false }, // 横線を非表示
            },
            {
                type: 'value',
                axisLabel: { show: false }, // Y軸ラベルを非表示
                splitLine: { show: false }, // 横線を非表示
                // scale: true, // スケールを自動調整
                inverse: true
            },
        ],
        series: [
            {
                name: 'クリック数',
                type: 'line',
                data: clickData,
                yAxisIndex: 0,
            },
            {
                name: '表示回数',
                type: 'line',
                data: impressionData,
                yAxisIndex: 1,
            },
            {
                name: 'CTR',
                type: 'line',
                data: ctrData,
                yAxisIndex: 2,
                tooltip: {
                    valueFormatter: function (value: number) {
                        return roundWithScale(value, 2).toFixed(2) + '%';
                    }
                },
            },
            {
                name: '平均掲載順位',
                type: 'line',
                data: positionData,
                yAxisIndex: 3,
            },
        ]
    }
    
    return <ReactECharts option={option} style={{ height: '100%', width: '100%' }} onEvents={undefined} />
}
