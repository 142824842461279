import axios from 'axios'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AllPageSegmentChannelLandingLog, SearchAllPageSegmentChannelLandingLog } from '../../Types/AllPageSegmentChannelLandingLog'


export const getAllPageSegmentChannelLandingLogList = async (searchParams: SearchAllPageSegmentChannelLandingLog | undefined) => {
    const { data } = await axios.get('/api/grouped_page_segment_channel_landing_logs', { params: searchParams })
    return data
}

export const useAllPageSegmentChannelLandingLogList = (searchParams: SearchAllPageSegmentChannelLandingLog | undefined): UseQueryResult<AllPageSegmentChannelLandingLog[]> => {
    return useQuery({
        queryKey: ['getAllPageSegmentChannelLandingLogList', searchParams],
        queryFn: () => getAllPageSegmentChannelLandingLogList(searchParams),
        enabled: !!searchParams, // searchParamsが未定義の場合はクエリを無効にする
    })
}
