import { FC, useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useLocation, useNavigate } from "react-router-dom"
import { Page404 } from "../Page404"
import LanguageIcon from '@mui/icons-material/Language'
import { Loading } from "../../../Ui/Loading"
import { H1 } from "../../../Ui/H1"
import { MainContent } from "../../../Ui/MainContent"
import { Input, Label } from "../../../Ui/Form"
import { SubmitButton } from "../../../Ui/Button"
import { Site } from "../../../../Types/Site"
import { getSite, updateSite } from "../../../../Hooks/User/Site"

const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const SiteEdit: FC = () => {

    const query = useQueryString()
    const id = query.get('id')

    if (!id) return <Page404 />

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

    const useFormMethods = useForm<Site>()
    const { handleSubmit, setValue, setError } = useFormMethods

    const onSubmit: SubmitHandler<Site> = (data) => {
        setIsLoading(true)

        updateSite(data)
            .then(() => {
                navigate('/sites', { state: 'サイトを更新しました' })
            })
            .catch((error) => {
                setIsLoading(false)
                const errors = error.response.data.errors
                if (errors.name) setError('name', { type: 'custom', message: errors.name.join('/') })
                if (errors.url) setError('url', { type: 'custom', message: errors.url.join('/') })
            })
    }

    
    useEffect(() => {
        const getData = async (id: string) => {
            const data = await getSite(id)

            setValue('id', data.id)
            setValue('name', data.name)
            setValue('url', data.url)

            setIsLoading(false)
        }

        getData(id)
    }, [])

    if (isLoading) return <Loading isLoading={isLoading} />
    return (
        <>  
            <H1 icon={<LanguageIcon />}>サイト設定</H1>
            <MainContent>
                <FormProvider {...useFormMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input name="id" type="hidden" disabled={true} />

                        <Label isRequire={true}>サイト名</Label>
                        <Input name="name" type="text" />
                    
                        <Label isRequire={true}>URL</Label>
                        <Input name="url" type="text" />

                        <div className="mt-8">             
                            <SubmitButton />
                        </div>
                    </form>
                </FormProvider>
            </MainContent>
        </>
    )
}