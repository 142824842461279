import { Tooltip } from "@mui/material"
import { truncateString } from "../../Utils/Str"

interface UrlTooltipProps {
    url: string
    length?: number
}
export const UrlTooltip = ({ url, length=50 }: UrlTooltipProps) => {
    if (url.length > length) {
        return (
            <Tooltip title={url} arrow>
                <p className="page-url">{truncateString(url, length)}</p>
            </Tooltip>
        )
    } else {
        return (
            <p className="page-url">{truncateString(url, length)}</p>
        )
    }
}